/**
 * Main order status used in DB
 *
 * Order status flow:
 * | FROM      | TO       | TRIGGER                                   |
 * |-----------|----------|-------------------------------------------|
 * | -         | NEW      | Every new order is set to NEW by default  |
 * | PENDING   | NEW      | Clicked button "Return to Drafts"         |
 * | NEW       | PENDING  | Order paid OR clicked button "To Work"    |
 * | PENDING   | IN_WORK  | Order stickers printed                    |
 * | IN_WORK   | CLOSED   | Clicked button "READY"                    |
 * | CLOSED    | SHIPPED  | Clicked button "SHIP"                     |
 */
export enum OrderStatus {
    STATUS_NEW = 1,
    STATUS_PENDING = 3,
    STATUS_IN_WORK = 2,
    STATUS_CLOSED = 10,
    STATUS_SHIPPED = 11,
}

/**
 * Order status in text form for more convenient usage
 */
export type OrderStatusTxt = 'new' | 'pending' | 'work' | 'closed' | 'shipped';

/**
 * @deprecated Use OrderStatusTxt
 */
export type TOrderStatus = OrderStatusTxt | 'all';

const orderStatusMap: Record<OrderStatusTxt, OrderStatus> = {
    new: OrderStatus.STATUS_NEW,
    pending: OrderStatus.STATUS_PENDING,
    work: OrderStatus.STATUS_IN_WORK,
    closed: OrderStatus.STATUS_CLOSED,
    shipped: OrderStatus.STATUS_SHIPPED,
};

export function orderStatusFrom(value: OrderStatusTxt): OrderStatus {
    return orderStatusMap[value];
}

export function orderStatusListFrom(list: OrderStatusTxt[]): OrderStatus[] {
    return Array.isArray(list)
        ? list.flatMap(value => (orderStatusMap[value] ? orderStatusMap[value] : []))
        : [];
}
